import React from "react";
import { StaticImage } from "gatsby-plugin-image";


const HistoryDesktop = () => {
  console.log("desktop");

    return (
        <div className="history mt-60 mb-30">
        <div className="history_body container">
          <div className="history_year">
            <div className="history_header">1992</div>
            <div className="history_text" id="circle_1_content">
              Основание компании DENDOR Valve Industrial Sp.zo.o. в г. Гданьск,
              Польша.
            </div>

            <div className="circle" id="circle_1"></div>
            <div className="arrow">
              <StaticImage
                alt="Прайс лист на продукцию компании Dendor"
                src="../../images/arrow.png"
                placeholder="#fff"
              />
            </div>
          </div>
          <div className="history_year">
            <div className="history_header">1999</div>
            <div className="history_text">
              Начало поставок трубопроводной арматуры DENDOR Valve Industrial на
              территорию России
            </div>
            <div className="circle"></div>
            <div className="arrow">
              <StaticImage
                alt="Прайс лист на продукцию компании Dendor"
                src="../../images/arrow.png"
                placeholder="#fff"
              />
            </div>
          </div>
          <div className="history_year">
            <div className="history_header">2010</div>
            <div className="history_text">
              ЗАО «ЭНЕРГИЯ» — эксклюзивный дистрибьютор DENDOR.
            </div>
            <div className="circle"></div>
            <div className="arrow">
              <StaticImage
                alt="Прайс лист на продукцию компании Dendor"
                src="../../images/arrow.png"
                placeholder="#fff"
              />
            </div>
          </div>
        </div>
        <div className="history_body container history__border">
          <div className="history_year order_4">
            <div className="history_header">2019</div>
            <div className="history_text">
              Запуск третьей производственной площадки в г. Тула.
            </div>
            <div className="circle "></div>
            <div className="arrow">
              <StaticImage
                alt="Прайс лист на продукцию компании Dendor"
                src="../../images/arrow.png"
                placeholder="#fff"
              />
            </div>
          </div>
          <div className="history_year order_3">
            <div className="history_header">2018</div>
            <div className="history_text">
              Запуск второй производственной площадки в г. Тихвин.
            </div>
            <div className="circle"></div>
            <div className="arrow svg_test">
              <StaticImage
                alt="Прайс лист на продукцию компании Dendor"
                src="../../images/arrow.png"
                placeholder="#fff"
              />
            </div>
          </div>
          <div className="history_year order_2">
            <div className="history_header">2016</div>
            <div className="history_text" id="circle_1_content">
              Запуск производства трубопроводной арматуры DENDOR в России.
            </div>

            <div className="circle" id="circle_2"></div>
            <div className="arrow">
              <StaticImage
                alt="Прайс лист на продукцию компании Dendor"
                src="../../images/arrow.png"
                placeholder="#fff"
              />
            </div>
          </div>
          <div className="history_year order_1">
            <div className="history_header">2015</div>
            <div className="history_text" id="circle_1_content">
              Принято решение о локализации производства в России.
            </div>
            <div className="circle" id="circle_1"></div>
            <div className="arrow">
              <StaticImage
                alt="Прайс лист на продукцию компании Dendor"
                src="../../images/arrow.png"
                placeholder="#fff"
              />
            </div>
          </div>
        </div>
      </div>
    )




}

export default HistoryDesktop