import React from "react";
import Layout from "../components/layout";
import SvgIcons from "../components/svgIcons/svgIcons";

import History from "../components/History/History";
import { useMediaQuery } from 'react-responsive';
import HistoryDesktop from "../components/History/HistoryDesktop";
import HistoryMobile from "../components/History/HistoryMobile";

const About = () => {
  const is700px = useMediaQuery({ query: '(min-width: 767px)' })
  return (
    <Layout>
      <div className="container content_header text-center mt-60">
        <h1>История компании</h1>
        <div className="mt-60">
          <p className="p20">
            АО «ЭНЕРГИЯ» поставляет надежную трубопроводную арматуру DENDOR во
            все регионы России. За последние годы компания не только заняла одну
            из лидирующих позиций на рынке, но и создала надежную дилерскую
            сеть.
          </p>
        </div>
      </div>


     <HistoryDesktop/>
    
      <div className="container mt-90">
        <div className="info mt-30 mb-30 ">
          <p>
            На сегодняшний день у АО «ЭНЕРГИЯ» три производственные площадки:
          </p>
        </div>
        <div className="zavod_container">
          <div className="zavod">
            <div className="zavod_img">
              <SvgIcons width="64" height="64" name="zavod1" />
            </div>
            <div className="zavod_text">
              п. Ропша
              <br /> <span>Главная производственная площадка</span>
            </div>
          </div>
          <div className="zavod">
            <div className="zavod_img">
              <SvgIcons width="64" height="64" name="zavod1" />
            </div>
            <div className="zavod_text">
              г. Тихвин <br />
              <span>Литейное производство</span>
            </div>
          </div>
          <div className="zavod">
            <div className="zavod_img">
              <SvgIcons width="64" height="64" name="zavod1" />
            </div>
            <div className="zavod_text">
              г. Тула <br />
              <span>Изготовление модельной оснастки</span>
            </div>
          </div>
        </div>
        <div className="video mt-90  mb-30 text-center">
          <h2 className="mb-30">
          ПРОИЗВОДСТВО ТРУБОПРОВОДНОЙ АРМАТУРЫ DENDOR В РОССИИ
          </h2>
          <iframe
            width="100%"
            height="360px"
            src="https://www.youtube.com/embed/jSP_ivZWvK8"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <div className="container cardFive mb-30">
        <div className="cardFive_box">
          <div className="cardFive_body">
            <div className="cardFive_header"> &gt; 100 </div>
            <div className="cardFive_text">
              <p>тонн литья ежемесячно</p>
            </div>
          </div>
        </div>
        <div className="cardFive_box">
          <div className="cardFive_body">
            <div className="cardFive_header"> &gt; 1000</div>
            <div className="cardFive_text">
              <p>Наименований продукции</p>
            </div>
          </div>
        </div>
        <div className="cardFive_box">
          <div className="cardFive_body">
            <div className="cardFive_header"> 21</div>
            <div className="cardFive_text">
              <p>Компания партнер</p>
            </div>
          </div>
        </div>
        <div className="cardFive_box">
          <div className="cardFive_body">
            <div className="cardFive_header"> &gt; 15 000 </div>
            <div className="cardFive_text">
              <p>Клиентов</p>
            </div>
          </div>
        </div>
        <div className="cardFive_box">
          <div className="cardFive_body">
            <div className="cardFive_header"> &gt; 20 000 </div>
            <div className="cardFive_text">
              <p>Объектов поставок</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default About;
